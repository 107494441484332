button {
    background: none;
    border: none;
    padding: 0.8em;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    &:hover {
        background: none;
        cursor: pointer;
    }
  }