@import 'styles/global.scss';

.c-matrixPreview {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    width: 100px;
    height: 100px;

    border: 2px $s-color--grey solid;
    background-color: white;

    &__container {
        //margin: 3px 2px 20px 2px;
    }

    &__dot {
        height: 4px;
        width: 4px;
        margin: 1px;
        border-radius: 50%;

        background-color: $s-color--secondary;
    }

    &__title {
        width: 100%;
        margin-top: 1px;
        padding: 2px;

        text-align: center;
        color: white;

        border-radius: 8px;
        background-color: $s-color--lightGrey;

        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &--selected {
        border: 2px $s-color--primary solid;
    }

    &:hover {
        border: 2px $s-color--primary-dark solid;
    }
}