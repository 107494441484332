@import 'styles/global.scss';

#root {
    max-width: 100vw;
}

.c-app {
    min-height: 100vh;
    min-width: 350px;
    max-width: 100vw;
}