@import 'styles/global.scss';
@import '../../components/BackpackItem/BackpackItem.scss';

.c-backpack {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    height: 100%;
    max-height: inherit;
    border: 2px $s-color--primary solid;
    background-color: #d8d8d8;

    &__container {
        width: 100%;
        max-height: inherit;    
        height: 100%;
    }

    &__folders {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        width: 100%;
        min-height: 133px;
        overflow: hidden;

        border-bottom: 2px $s-color--grey solid;

        & > div {
            @extend .c-backpackItem;
        }
    }

    &__matrixes {
        display: flex;
        flex-flow: row wrap;
        flex-grow: 2;
        align-content: flex-start;
        width: 100%;
        min-height: 133px;

        & > div {
            @extend .c-backpackItem;
        }
    }

    &--disabled {
        pointer-events: none;
    }

    &__renameMatrixNameInput {
        margin: 0 auto;
        padding: 0.4em 0.4em;

        font-size: 1.2em;
        text-align: left;
    }
}