@import 'styles/global.scss';

.c-batteryIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: $s-color--success;

    &__warning {
        color: $s-color--warning;
    }

    &__error {
        color: $s-color--danger;
    }
}