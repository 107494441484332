@import 'styles/global.scss';

.c-settings {
    max-height: 70vh;

    & h3 {
        text-align: center;
        margin: 0.8em 0;
    }

    & h5 {
        margin: 0.8em 0;
    }

    > input, select {
        margin: 0 auto;
        padding: 2px 6px;
        font-size: 0.9em;
        text-align: left;
    }

    &__JavascriptEditorType {
        width: 200px;
    }
}