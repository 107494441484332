@import 'styles/global.scss';

.c-console {
    position: relative;
    height: 100%;
    min-width: 200px;
    width: auto;
    flex-grow: 1;

    padding: 0.8em;
    background-color: #202124;
    color: #fff;
    max-height: 100%;

    &__header {
        display: flex;
        align-items: center;
        text-align: left;
        border-bottom: 2px solid $s-color--grey;

        & h4 {
            margin: 0.2em 0.4em 0.2em 0;
            padding-right: 0.4em;
            border-right: 1px solid $s-color--grey;
        }
    }

    &__body {
        margin: 0 0.2em;
        overflow-y: scroll;
        height: calc(100% - 30px);
    }

    &__logTimestamp {
        margin: 0 0.4em 0 0;
        padding: 0 0.4em 0 0;
        border-right: 1px solid $s-color--grey;

        font-size: 0.9em;
        color: $s-color--grey;
    }

    &__log {
        display: flex;
        border-bottom: 1px solid $s-color--grey;
        white-space: nowrap;

        &--system {
            color: $s-color--warning;
        }
    }

    &__clearConsole {
        display: flex;
        margin-left: auto;

        &:hover {
            cursor: pointer;
            color: $s-color--grey;

            & > svg {
                color: $s-color--grey;
            }
        }
    }
}