/*
 *  Responsive Tools
 *    Responsive tools
 *
 *  Dependencies:
 *
 *  Version:
 *    1.0.0 - 2018/01/24
 */

 @mixin t-media-query($media-query) {
    $breakpoint-found: false;
  
    @each $breakpoint in $s-breakpoints {
      $name: nth($breakpoint, 1);
      $declaration: nth($breakpoint, 2);
  
      @if $media-query == $name and $declaration {
        $breakpoint-found: true;
  
        @media only screen and #{$declaration} {
          @content;
        }
      }
    }
  
    @if $breakpoint-found == false {
      @warn 'Breakpoint "#{$media-query}" does not exist';
    }
  }
  