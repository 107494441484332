@import 'styles/global.scss';

.c-matrixEditor {
    display: flex;
    width: 100%;

    &__matrix {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        width: 390px;
        height: 390px;

        border: 2px $s-color--primary solid;
        background-color: white;
        
        //Increase Matrix Editor Size
        @include t-media-query($s-large-up) {
            width: 470px;
            height: 470px;
        }
    }

    &__hex {
        margin-top: 10px;
        width: 100%;

        display: flex;
        align-items: center;
    }

    &__hexInput {
        margin-left: 5px;
        padding: 5px 10px;
        flex-grow: 1;

        font-size: 1em;
        text-align: center;
    }

    &__left {

    }

    &__right {
        margin-left: 20px;
    }

    &__button {
        width: 10em;

        &:disabled {
            background-color: $s-color--grey;
        }
    }

    &--disabled {
        pointer-events: none;
    }
}

.o-matrixDot {
    height: 20px;
    width: 20px;
    margin: 2px;
    border-radius: 50%;

    background-color: $s-color--veryLightGrey;

    //cursor: url('../../assets/pen.png'), auto;

    &--active{
        background-color: $s-color--secondary;
        //cursor: url('../../assets/eraser.png'), auto;
    }

    //Increase Matrix Editor Size
    @include t-media-query($s-large-up) {
        height: 25px;
        width: 25px;
    }
}