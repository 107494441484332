@import 'styles/global.scss';

.c-consoleDecorator {
    &__normal {
        
    }

    &__config {
        color: $s-color--white;
        background-color: $s-color--darkGrey;
    }

    &__ultra {
        
    }

    &__VA {
        
    }

    &__event {
        &SensorAction {
            color: black;
            background-color: $s-color--info;
        }
        &Warning {
            color: black;
            background-color: $s-color--warning;
        }
        &Error {
            color: black;
            background-color: $s-color--danger;
        }
    }

    &__wifiConnected {
        color: black;
        background-color: $s-color--success;
    }

    &__sensor {
        color: black;
        background-color: $s-color--grey;
    }

    &__jsSystem {
        color: $s-color--warning;
        & > span {
            font-weight: 600;
            margin-right: 6px;
        }
    }

    &__jsLibError {
        color: $s-color--danger;
        & > span {
            font-weight: 600;
            margin-right: 6px;
        }
    }

    &__jsError {
        color: $s-color--danger;
        & > span {
            font-weight: 600;
            margin-right: 6px;
        }
    }

    &__whiteTimestamp {
        border-bottom: 1px solid $s-color--white !important;

        & > .c-console__logTimestamp {
            color: $s-color--white;
            border-right: 1px solid $s-color--white;
        }
    }
}