@import 'styles/global.scss';

.c-backpackFolder {
    width: 100px;
    height: 100px;
    border-radius: 8px;

    &__title {
        width: 100%;
        padding: 3px;

        overflow-wrap: break-word;
        text-align: center;
        color: white;

        border-radius: 8px;
        background-color: $s-color--lightGrey;

        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &--selected {
        background-color: $s-color--lightGrey;
    }

    & > svg {
        padding-top: 10px;
        display: block;
        margin: auto;
        width: 80px;
        height: 80px;
    }
}