@import 'styles/global.scss';

.c-serialErrorPhase {
    &__link {
        color: $s-color--white;
        background-color: $s-color--darkGrey;
        padding: 1px 3px;
        border-radius: 3px;
    }

    &__buttons {
        
    }
}