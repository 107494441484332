@import 'styles/global.scss';

.c-animationControls {
    margin-top: 20px;

    &__button {
        margin-left: 10px;
        padding: 0.8em;
        border-radius: 4px;
        background: $s-color--success;

        &:hover {
            background: $s-color--success-dark;
        }
    }

    &__fps {
        width: 100px;
        margin-left: 10px;
        margin-top: 10px;
        padding: 5px 10px;

        font-size: 1em;
        text-align: center;
    }
}