$s-modal-xs-width: 400px;
$s-modal-xs-min-width: 400px;
$s-modal-xs-max-width: 543px;
$s-modal-xs-min-height: 240px;
$s-modal-sm-width: 544px;
$s-modal-sm-min-width: 544px;
$s-modal-sm-max-width: 687px;
$s-modal-sm-min-height: 302px;
$s-modal-md-width: 688px;
$s-modal-md-min-width: 688px;
$s-modal-md-max-width: 927px;
$s-modal-md-min-height: $s-modal-sm-min-height;
$s-modal-lg-width: 928px;
$s-modal-lg-min-width: 928px;
$s-modal-lg-min-height: $s-modal-sm-min-height;
$s-modal-xlg-width: 1200px;
$s-modal-xlg-min-width: 1200px;
$s-modal-xlg-min-height: $s-modal-sm-min-height;
$s-modal-header-height: 54px;
$s-modal-footer-height: 56px;
$s-modal-footer-button-margin: 8px;