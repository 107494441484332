html, body {
    padding: 0;
    margin: 0;
    font-size: $s-font--size--base;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    font-family: $s-font--default;
    color: $s-color--black;
    line-height: 1.25;
}