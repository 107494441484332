@import 'styles/global.scss';

.c-matrixPage {
    padding: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;

    &__backpackPageTitle {
        margin: 5px 0;
    }

    &__left {
        width: 100%;

        &-top {
            width: 100%;
            height: 100%;
            max-height: calc(70vh - 70px);
        }

        &-bottom {
            margin-top: 40px;
            height: 100%;
            max-height: calc(30vh - 70px);
        }
    }

    &__right {
        margin-top: 30px;
        margin-left: 20px;
    }
}