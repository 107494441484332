@import 'styles/global.scss';

.c-navbar {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    height: calc(#{$s-dimensions-navBar-height} * 2);
    padding-bottom: 10px;

    z-index: $s-z--navbar;
    color: $s-color--white;
    position: fixed;
    background-color: $s-color--primary;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.14);

    @include t-media-query($s-small-up) {
        padding: 0;
        height: $s-dimensions-navBar-height;
        justify-content: space-between;
        flex-flow: row nowrap;
    }

    &__left {
        float: left;
        display: flex;
        align-items: center;
    }

    &__right {
        display: flex;
        & > * {
            margin-right: 10px;
        }
    }

    &__logoContainer, &__logoContainer:hover {
        padding: 0.5em;
        color: $s-color--white;
        text-decoration: none;
    }

    &__logo {
        height: 54px;
        width: auto;
        font-size: 1.4em;
        font-weight: 600;
        text-transform: uppercase;

        >img {
            height: inherit;
            width: inherit;
        }
    }

    &__projectFileNameInput {
        margin: 0 auto;
        padding: 0.4em 0.4em;

        font-size: 1.2em;
        text-align: left;
    }

    &__gamepadTypeLogo {
        width: 18px;
        height: 18px;
        margin: 0 0.2em;

        & path {
            stroke: #ffc66d;
        }
    }

    &__gamepadIndicator {
        &Area {
            margin-bottom: 0.1em;
        }

        &Icon {
            margin: 0 0.2em;
            width: 22px;
            height: 22px;

            &--connected path {
                stroke: $s-color--success;
            }
            &--disconnected path {
                stroke: $s-color--danger;
            }
        }
    }

    &__loadingSpinner {
        animation: spin 2s linear infinite;
    }

    &__connectedHardwareLogo {
        color: $s-color--success;
    }
}

@keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
}