@import 'styles/global.scss';

.c-dropDown {
    overflow: hidden;
    height: 100%;
    padding: 0px 0.1em;
    
    &__button {
        display: inline-block !important;
        color: $s-color--white;
        height: 70px;
        padding: 0.8em 0.6em;
        width: auto;
        outline: none;
        background-color: inherit;
        //margin: 0; //Important for vert on phones

        &:hover {
            background-color: $s-color--primary-light;
        }
    }

    &__content {
        display: none;
        position: absolute;
        background-color: inherit;
        overflow: auto;
        max-height: 80vh;
        min-width: 110px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: $s-z--dropDownContent;

        & > * {
            @extend .c-dropDown__contentItem;
        }

        &--open {
            display: block;
        }
    }

    &__contentItem {
        display: flex;
        text-align: left;
        float: none;
        color: $s-color--white;
        padding: 0.6em;
        width: 100%;
        background-color: $s-color--primary;

        &:hover {
            background-color: $s-color--primary-light;
        }
    }
}