@import 'styles/global.scss';

.c-deviceConnection {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &__connectButton {
        margin-left: 10px;
        background: $s-color--success;
        padding: 0.6em;
        border-radius: 4px;

        &:hover {
            background: $s-color--success-dark;
        }
    }

    &__disconnectButton {
        margin-left: 10px;
        background: $s-color--danger;
        padding: 0.6em;
        border-radius: 4px;

        &:hover {
            background: $s-color--danger-dark;
        }
    }

    &__connectionText {
        width: 130px;
    }

    &__mirrorMatrix {
        margin-left: 10px;
    }

    &__mirrorMatrixButton {
        margin-left: 10px;
        background: $s-color--success;
        padding: 0.6em;
        border-radius: 4px;

        &:hover {
            background: $s-color--success-dark;
        }

        &-off {
            background: $s-color--danger;
            &:hover {
                background: $s-color--danger-dark;
            }
        }
    }
}