@import 'styles/global.scss';

.p-remotePage {
    margin-top: 20px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    @include t-media-query($s-small-up) {
        flex-flow: row;
    }

    &__remote {
        width: 100%;
        height: 100%;
        margin-right: 20px;
        margin-bottom: 20px;

        display: grid;
        grid-template-columns: 220px 1fr 2fr;
        grid-template-rows: repeat(auto-fit, auto);
        gap: 10px;
        justify-items: center;

        &--grid {
            & > .p-remotePage__crane {
                grid-row: 3;
            }
    
            & > .p-remotePage__webcam {
                grid-row: 2;
            }
    
            @include t-media-query($s-medium-up) {
                & > .p-remotePage__crane {
                    grid-row: 2;
                }
    
                & > .p-remotePage__webcam {
                    grid-row: span 2;
                    grid-column: span 2;
                }
            }
    
            @include t-media-query($s-xlarge-up) {
                & > .p-remotePage__crane {
                    grid-row: 1;
                    grid-column: 2;
                }
    
                & > .p-remotePage__webcam {
                    grid-column: 3;
                }
            }
        }   
    }



    &__remoteController {
        border: 1px solid $s-color--darkGrey;
        width: 240px;
        height: 322px;

        display: flex;
        flex-flow: column;
        justify-content: space-between;

        &--omni {
            height: 352px;
        }
    }
    
    &__remoteInner {
        padding: 1em;
        width: 100%;
        height: 100%;
    }

    &__remoteButtons {
        display: block;

        & > div > button {
            width: 68px;
            height: 68px;
            font-size: 1.4em;

            background-color: $s-color--primary;

            &:hover {
                background-color: $s-color--primary-dark;
            }
        }
    }

    &__remoteSpeed {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $s-color--darkGrey;
    }

    &__remoteSpeedSlider {
        margin-top: 15px;
    }

    &__remoteSpeedInput {
        width: 35px;
        margin-left: 5px;
        font-size: 1em;
        padding: 0.2em;
        text-align: center;
       
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
    }

    &__remoteOmniCommands {
        margin-top: 5px;
        display: flex;

        & > input {
            width: 15px;
            height: 15px;
        }
         
        & > h5 {
            margin: 0;
        }
    }

    &__remoteJoystick {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__remoteSelector {
        display: flex;

        &Button {
            width: 100%;
            padding: 0.4em 0.4em;
            background-color: $s-color--lightGrey;

            &--active, &:hover {
                background-color: $s-color--info;
            }
        }
    }

    &__remoteTank {
        width: 100%;
        height: 100%;

        display: flex;
        
        & .c-slider {
            margin: 0 45px;
        }
    }

    &__crane {
        height: max-content;

        min-width: 240px;
        max-width: 400px;
    
        border: 1px solid $s-color--darkGrey;   
    }

    &__craneInner {
        display: flex;
        flex-flow: column;

        & h5 {
            margin: 0.2em 0 0.8em 0;
        }
    }

    &__cranePresets {
        display: flex;
        flex-flow: column;

        margin-bottom: 10px;
    }

    &__cranePreset {
        margin: 5px;
    }

    &__craneTitle {
        margin: 0.4em 0;
        text-align: center;
    }

    &__craneJointTitleArea {
        display: flex;
        justify-content: space-between;
        padding: 2px 4px;
    }

    &__craneSelector {
        display: flex;
    }

    &__webcam {
        
    }

    &__sensorDisplaySensor {
        position: relative;
        background: $s-color--black;

        &--sonar1 {
            left: 130px;
            top: 60px;
        }

        &--sonar2 {
            left: 310px;
            top: 60px;
        }

        &--encoder1 {
            top: 180px;
            right: 50px;
        }

        &--encoder2 {
            top: 180px;
            left: 350px;
        }

        &--light1 {
            top: 210px;
            left: 90px;
        }

        &--color {
            top: 210px;
            left: 100px;
        }

        &--light2 {
            top: 210px;
            left: 180px;
        }

        &--red {
            background-color: $s-color--danger;
            top: 150px;
            left: -30px;
        }

        &--green {
            background-color: $s-color--success;
            top: 150px;
            left: -20px;
        }

        &--blue {
            background-color: $s-color--info;
            top: 150px;
            left: -10px;
        }

        &--white {
            color: $s-color--black;
            background-color: $s-color--white;
            top: 150px;
            left: 0px;
        }

        &--compass {
            top: 35px;
            right: 45px;
        }
    }

    &__sensorDisplayImg {
        width: 100%;
    }

    &__gamepad {
        &Icon {
            width: 50px;
            height: 50px;

            &--connected path {
                stroke: $s-color--success;
            }
            &--disconnected path {
                stroke: $s-color--danger;
            }
        }
    }
}