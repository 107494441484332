@import 'styles/global.scss';

.c-modal {
    position: relative;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .5);
    min-width: $s-modal-xs-width;
    z-index: $s-z--modalBase;
    text-align: left;
    max-height: 90vh;

    white-space: normal;

    &__xs { //Extra Small
        width: $s-modal-xs-width;
        .modal-body {
            min-height: ($s-modal-xs-min-height - $s-modal-footer-height)px;
        }
        .modal-header + .modal-body {
            min-height: ($s-modal-xs-min-height - $s-modal-header-height - $s-modal-footer-height)px;
        }
    }

    &__sm { //Small
        width: $s-modal-sm-width;
        .modal-body {
            min-height: ($s-modal-sm-min-height - $s-modal-footer-height)px;
        }
        .modal-header + .modal-body {
            min-height: ($s-modal-sm-min-height - $s-modal-header-height - $s-modal-footer-height)px;
        }
    }

    &__md { //Medium
        width: $s-modal-md-width;
        .modal-body {
            min-height: ($s-modal-md-min-height - $s-modal-footer-height)px;
        }
        .modal-header + .modal-body {
            min-height: ($s-modal-md-min-height - $s-modal-header-height - $s-modal-footer-height)px;
        }
    }

    &__lg { //Large
        width: $s-modal-lg-width;
        .modal-body {
            min-height: ($s-modal-lg-min-height - $s-modal-footer-height)px;
        }
        .modal-header + .modal-body {
            min-height: ($s-modal-lg-min-height - $s-modal-header-height - $s-modal-footer-height)px;
        }
    }

    &__lg { //Large
        width: $s-modal-xlg-width;
        .modal-body {
            min-height: ($s-modal-xlg-min-height - $s-modal-footer-height)px;
        }
        .modal-header + .modal-body {
            min-height: ($s-modal-xlg-min-height - $s-modal-header-height - $s-modal-footer-height)px;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(51, 51, 51, .55);
        z-index: $s-z--modalOverlay;
    
        white-space: nowrap;
        overflow-x: auto;
    
        // Centering in the Unknown
        // https://css-tricks.com/centering-in-the-unknown/
        &Centered {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
    
            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -.25em; // Adjusts for spacing
            }
    
            .c-modal__body {
                display: inline-block;
                vertical-align: middle;
                text-align: left;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-height: $s-modal-header-height;
        padding: 16px 16px;

        border-bottom: 1px solid $s-color--grey;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        margin: 0;
    }

    &__exit {
        font-size: 1.25em;
        color: $s-color--grey;
        &:hover {
            color: $s-color--lightGrey;  
            cursor: pointer;
        }
    }

    &__body {
        width: 100%;
        padding: 16px 16px;
        height: 100%;
        //max-height: calc(90vh - #{$s-modal-header-height} - #{$s-modal-footer-height});
    }

    &__footer {
        min-height: $s-modal-footer-height;
        margin-top: 0;
        background-color: #eee;
        padding: 16px 16px;
        display: flex;
        justify-content: space-between;
    }

    &__footerLeft {
        display: flex;
        align-items: center;
    }

    &__footerRight {
    }
}