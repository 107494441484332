.p-codingPage {
    &__codingTab {
        display: flex;
        flex-flow: row nowrap;

        height: 100%;
    }

    &__buttonsTab {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        height: 100%;
    }

    &__buttons {
        display: flex;
        flex-flow: column nowrap;
    }

    &__button {
        margin-top: 10px;
    }

    &__buttonDivider {
        margin: 10px 0;
        width: 100%;
        border-top: 2px solid black;
    }

    &__console {
        min-width: 400px;
    }

    &__scrollBarHorizontal {
        overflow-x: auto;
        box-shadow: inset -40px 10px 47px -41px rgba(0,0,0,0.2);

        &::-webkit-scrollbar {
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            outline: 1px solid slategrey;
        }
    }
}