@import 'styles/global.scss';

.p-configPage {
    margin-top: 10px;

    &__tabs {
        width: 100%;
        margin-top: 20px;
    }

    &__tableContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__table {
        width: 100%;
        text-align: center;
        border: 2px solid $s-color--black;

        & > tr, td {
            border: 1px solid $s-color--lightGrey;
            padding: $s-gutter--xsmall;
        }
    }

    &__tableHeader {
        background-color: $s-color--lightGrey;
        color: $s-color--white;
        font-size: 1.6em;

        & > th {
            padding: 0;
            border: 2px solid $s-color--black;
        }
    }

    &__RFIDButtons {
        width: 100%;
        margin: 10px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    &__spinner {
        margin-left: 10px;
        animation: spin 5000ms infinite linear;
    }

    &__tableSettingName {
        font-size: 1.1em;
        font-weight: 600;
    }

    &__tableSettingNameCrane {
        font-size: 1.1em;
        font-weight: 600;

        width: 150px;
    }

    &__tableSettingNewValueDisabled {
        background-color: $s-color--darkGrey;
    }

    &__tableSettingValue {
        font-size: 1.1em;
        font-weight: 500;

        &--black {
            background-color: $s-color--darkGrey;
        }
    }

    &__tableSettingValueCrane {
        font-size: 0.8em;
        font-weight: 500;
        display: flex;
        align-items: center;

        &--black {
            background-color: $s-color--darkGrey;
        }

        & > input {
            width: 70px;
        }

        & > .c-slider {
            margin: 15px 10px 0 10px;
        }
    }

    &__tableSettingSetCrane {
        width: 110px;
    }

    &__select {
        &__value-container {
            display: flex;
            justify-content: center;
        }
    }

    &__tableRow {
        background-color: $s-color--white;

        &--light {
            background-color: $s-color--success;
        }

        & input {
            text-align: center;
        }
    }

    &__modemBinding {
        float: right;
        width: 140px;
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}