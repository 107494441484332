@import 'styles/global.scss';


.c-slider {
    height: 20px;
    width: 100%;

    &--vertical {
        width: 20px;
        height: 100%;
    }

    &__thumb {
        position: absolute;
        
        width: 20px;
        height: 20px;
        background: $s-color--primary-dark;
        border-radius: 50%;
        outline: 0;

        &--horizontal {
            top: -8px;
        }

        &--vertical {
            left: -8px;
        }

        &--disabled {
            background: $s-color--grey;
        } 
        
        &--active, &:hover, &:active {
            box-shadow: 0px 0px 0px 8px rgba($s-color--primary, 0.3);
            cursor: pointer;
        }
    }

    &__track {
        top: 0;
        bottom: 0;
        background: $s-color--primary-dark;
        border-radius: 2px;

        &--horizontal {
            height: 4px;
        }

        &--vertical {
            width: 4px;
        }

        &--disabled {
            background: $s-color--grey !important;
        }

        &--inactive {
            background: $s-color--primary;
        }
    }
}