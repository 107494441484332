$s-color--primary: #4C97FF;
$s-color--primary-light: #66a6ff; //One shades lighter https://www.w3schools.com/colors/colors_picker.asp?color=4C97FF
$s-color--primary-veryLight: #b3d2ff;
$s-color--primary-dark: #075FE8;

$s-color--secondary: #E20000;
$s-color--secondary-dark: #b90000;

$s-color--success: #00C851;
$s-color--success-dark: #007E33;

$s-color--info: #33b5e5;
$s-color--info-dark: #0099CC;

$s-color--warning: #ffc34d;
$s-color--warning-dark: #FF8800;

$s-color--danger: #ff4444;
$s-color--danger-dark: #CC0000;

$s-color--black: #000;
$s-color--white: #fff;

$s-color--grey: #888;
$s-color--lightGrey: #a7a7a7;
$s-color--veryLightGrey: #e8e8e8;
$s-color--darkGrey: #333;