@import 'styles/global.scss';

.c-editorTools {
    &__bottom {
        margin-top: 20px;
    }

    &__button {
        padding: 0.8em;
        border-radius: 4px;
        background: $s-color--success;

        &:not(:first-child) { //Eveything except first in list needs to be pushed out
            margin-left: 10px;
        }

        &:hover {
            background: $s-color--success-dark;
        }

        &--delete {
            background: $s-color--danger;

            &:hover {
                background: $s-color--danger-dark;
            }
        }

        &:disabled {
            background-color: $s-color--grey;
        }
    }

    &__newMatrixNameInput {
        margin: 0 auto;
        padding: 0.4em 0.4em;

        font-size: 1.2em;
        text-align: left;
    }
}