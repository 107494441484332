@import '../../styles/global.scss';

.c-page {
    min-height: 80vh;
    height: 100%;
    //width: calc(100% - #{$s-dimensions-navBarVertical-width});
    width: 100%;
    //margin-left: $s-dimensions-navBarVertical-width;
    padding-top: calc(#{$s-dimensions-navBar-height} * 2);
    transition: 0.1s ease-in-out;
    transition-property: width margin-left;

    @include t-media-query($s-small-up) {
        padding-top: $s-dimensions-navBar-height;
    }

    &.has-background {
        position: relative;
    }
    
    &__boundary {
        max-width: $s-screen-boundary;
        margin: 0 auto;

        &.is-full {
            position: relative;
            height: 100%;
        }

        &.is-small {
            max-width: $s-screen-boundary / 2;
        }
    }

    &__background {
        position: fixed;
        z-index: $s-z--background;
        width: 100%;
        height: 100%;
    }

    &__inner {
        height: 100%;
        width: 100%;
        padding: 0 $s-gutter--small $s-gutter--small $s-gutter--small;
    }

/*     &--verticalBarCollapsed {
        width: calc(100% - #{$s-dimensions-navBarVertical-width-collapsed});
        margin-left: $s-dimensions-navBarVertical-width-collapsed;
        transition: 0.1s ease-in-out;
        transition-property: width margin-left;
    } */
}