/*
 *  Responsive Settings
 *    Provides settings for responsive tools
 *
 *  Dependencies:
 *
 *  Version:
 *    1.0.1 - 2018/01/31
 */

//Pixel size definitions
$s-screen-xxsmall: 350px;
$s-screen-xsmall: 500px;
$s-screen-small: 750px;
$s-screen-medium: 1000px;
$s-screen-large: 1250px;
$s-screen-xlarge: 1500px;

$s-screen-boundary: $s-screen-medium;

//Size definitions
$s-xxsmall: 'xxsmall';
$s-xsmall: 'xsmall';
$s-small: 'small';
$s-medium: 'medium';
$s-large: 'large';
$s-xlarge: 'xlarge';

//Groups
$s-xxsmall-up: 'xxsmall';
$s-xsmall-up: 'xsmall';
$s-small-up: 'small-up';
$s-medium-up: 'medium-up';
$s-large-up: 'large-up';
$s-xlarge-up: 'xlarge-up';

//Breakpoints
$s-breakpoints: (
    $s-xxsmall     '(max-width: #{$s-screen-xxsmall - 1})',
    $s-xsmall     '(max-width: #{$s-screen-xsmall - 1}) and (max-width: #{$s-screen-small - 1})',
    $s-small     '(max-width: #{$s-screen-small - 1}) and (max-width: #{$s-screen-medium - 1})',
    $s-medium     '(min-width: #{$s-screen-medium}) and (max-width: #{$s-screen-large - 1})',
    $s-large      '(min-width: #{$s-screen-large}) and (max-width: #{$s-screen-xlarge - 1})',
    $s-xlarge     '(min-width: #{$s-screen-xlarge})',

    $s-xxsmall-up  '(min-width: #{$s-screen-xxsmall})',
    $s-xsmall-up  '(min-width: #{$s-screen-xsmall})',
    $s-small-up   '(min-width: #{$s-screen-small})',
    $s-medium-up  '(min-width: #{$s-screen-medium})',
    $s-large-up   '(min-width: #{$s-screen-large})',
    $s-xlarge-up   '(min-width: #{$s-screen-xlarge})'
);