@import 'styles/global.scss';

.c-sensorTable {
    width: 100%;
    text-align: center;
    border: 2px solid $s-color--black;

    @include t-media-query($s-small-up) {
        width: 600px;
    }

    & > tbody > tr, td {
        border: 1px solid $s-color--lightGrey;
        padding: $s-gutter--xsmall;
        background-color: $s-color--white;
        font-size: 1.05em;
        font-weight: 600;
    }

    & tr > td:nth-child(3) {
        border-left: $s-color--black 2px solid;
    }

    & > tbody > tr:nth-child(even) td {
        background-color: $s-color--veryLightGrey;
    }

    &__cell--disabled {
        background: $s-color--darkGrey !important;

        &:nth-child(1) {
            border-right: 0;
        }
        &:nth-child(2) {
            border-left: 0;
            border-right: 0;
        }
    }

    &__cell--green {
        background: $s-color--success !important;
    }

    &__cell--red {
        background: $s-color--danger !important;
    }

    & thead {
        background-color: $s-color--lightGrey;
        color: $s-color--white;
        font-size: 1.4em;

        & > tr > th {
            padding: 0.1em 0.3em;
            border: 1px solid $s-color--grey;
        }
    }
}