

.c-flowChart__menu {
    overflow-x: auto;
    padding: 0;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 58px 2px rgba(34, 60, 80, 0.2);
    border-radius: 16px;
    background-color: rgba(255,255,255, .9);
}

.c-flowChart__content {
    white-space: nowrap;
    display: flex;
}

[data-cmd] {
    cursor: pointer;
}

.c-flowChart__menu svg {
    height: 72px;
    min-height: 42px;
    display: inline-block;
    padding: 15px 10px;
    stroke: #344767;
    stroke-width: 2px;
    fill: #fff;
    width: 72px;
    min-width: 72px;
}

.c-flowChart__menu .big {
    width: 92px;
    min-width: 92px;
}

@media only screen and (max-width: 700px) {
    .c-flowChart__menu {
        width: 100%;
        border-radius: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
    }

    .c-flowChart__content {
        align-self: center;
    }
}