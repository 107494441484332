@import 'styles/global.scss';


.c-button {
    padding: 0.7em;
    border-radius: 4px;
    background: $s-color--lightGrey;

    &:hover {
        background: $s-color--grey;
    }

    &:disabled {
        color: $s-color--white;
        background: $s-color--darkGrey;
        &:hover {
            background: $s-color--darkGrey;
            cursor: not-allowed;
        }
    }

    &--square {
        border-radius: 0px;
    }

    &--fullWidth {
        width: 100%;
    }

    &__info {
        background: $s-color--info;

        &:hover {
            background: $s-color--info-dark;
        }
    }

    &__success {
        background: $s-color--success;

        &:hover {
            background: $s-color--success-dark;
        }
    }

    &__danger {
        background: $s-color--danger;

        &:hover {
            background: $s-color--danger-dark;
        }
    }

    &__grey {
        background: $s-color--lightGrey;

        &:hover {
            background: $s-color--darkGrey;
        }
    }

    &__transparent {
        background: transparent;

        &:hover {
            background: transparent;
        }
    }

    &__sm {
        padding: 0.4em;
    }

    &__md {
        padding: 0.7em;
    }

    &__lg {
        padding: 1.1em;
    }
}