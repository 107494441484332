.p-consolePage {
    height: 70vh;

    &__buttons {
        & > button:not(:first-of-type) {
            margin-left: 10px;
        }
    }

    &__streamFreqArea {
        & > input {
            width: 80px;
        }
    }

    &__sendCommandArea {
        & > input {
            width: 425px;
        }
    }
}